<template>
  <div class="menu">
    <div class="menu-title row-center bg-primary lg white">帮助中心</div>
    <el-menu class="el-menu-vertical-demo" @open="
      (current) => {
        getHelpList(current);
      }
    " @select="handleSelect" text-color="#444444" :default-openeds="defaultOpen" :default-active="defaultActive">
      <el-submenu :index="index.toString()" v-for="(item, index) in categoryList" :key="index">
        <template slot="title">
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item-group v-for="(itemTwo, indexTwo) in item.list" :key="indexTwo">
          <el-menu-item :index="index + '-' + indexTwo">{{
              itemTwo.title
          }}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <!-- <el-submenu index="1">
        <template slot="title">
          <span>新手入门</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1">网站地图</el-menu-item>
          <el-menu-item index="1-2">购物流程</el-menu-item>
          <el-menu-item index="1-3">发票制度</el-menu-item>
          <el-menu-item index="1-4">用户服务协议</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <span>支付方式</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1">购物流程</el-menu-item>
          <el-menu-item index="1-2">发票制度</el-menu-item>
          <el-menu-item index="1-3">用户服务协议</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <span>配送服务</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1">发票制度</el-menu-item>
          <el-menu-item index="1-2">用户服务协议</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <span>售后保障</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1">退换货政策</el-menu-item>
          <el-menu-item index="1-2">退换货流程</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <span>关于大雨</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1">公司简介</el-menu-item>
          <el-menu-item index="1-2">在线客服</el-menu-item>
          <el-menu-item index="1-3">联系我们</el-menu-item>
        </el-menu-item-group>
      </el-submenu> -->
    </el-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Sidebar",
  props: {},
  components: {},
  data() {
    return {
      categoryList: [],
      defaultOpen: ["0"],
      defaultActive: "0-0",
    };
  },
  computed: {
    ...mapGetters(["commonData"]),
  },
  watch: {},
  methods: {
    // 回传详情ID
    handleSelect(e) {
      let arr = e.split("-");
      this.$emit("getHelpDetail", this.categoryList[arr[0]].list[arr[1]].id);
    },
    // 获取帮助中心分类
    async getHepCategory() {
      let { data } = await this.$api.gethelpCategoryListApi();
      if (this.commonData.mall_type != 0) {
        data = data.filter((el) => {
          return el.name != "关于大雨";
        });
      }
      this.categoryList = data;
      await this.getHelpList();
      this.handleSelect("0-0");
    },
    // 获取帮助中心列表
    async getHelpList(current = 0) {
      if (this.categoryList[current].hasOwnProperty("list")) return;
      let { data } = await this.$api.getHelpListApi({ cid: this.categoryList[current].id });
      this.$set(this.categoryList[current], "list", data.list);
    },
  },
  created() {
    this.getHepCategory();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.menu {
  overflow: hidden;
  width: 220px;

  .menu-title {
    height: 48px;
  }

  ::v-deep .el-menu {
    border: none;

    >li {
      &.is-opened {

        &.el-menu-item,
        .el-submenu__title {
          &::before {
            background-image: url("~@/assets/icon/icon-minus.png");
          }
        }
      }

      .el-menu--inline {
        padding: 0;
      }

      &.el-menu-item,
      .el-submenu__title {
        i {
          right: 34px;
        }

        height: 48px;
        line-height: 48px;
        font-size: 16px;
        padding: 0 0 0 60px !important;
        display: flex;
        align-items: center;

        &:hover {
          background-color: transparent !important;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .el-menu-item-group {
        .el-menu-item-group__title {
          padding: 0;
        }

        ul {

          // padding-bottom: 20px;
          .el-menu-item {
            font-size: 14px;
            line-height: 43px;
            height: 43px;
            padding: 0 0 0 60px !important;

            &:hover {
              background-color: $-color-center  !important;
              color: $-color-primary  !important;
            }
          }
        }
      }
    }
  }
}
</style>